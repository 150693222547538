<template>
  <div>
    <h3>What is your height?</h3>
    <div class="user-height">
      <template v-if="isImperial">
        <validation-provider
          class="user-height__item"
          name="feet"
          v-slot="{ errors }"
          :rules="{ numeric: true, required: true }"
        >
          <v-input
            type="text"
            v-model="feet"
            placeholder="ft"
            :class="{ error: errors[0] }"
            @blur="onImperialBlur"
          />
          <div class="error-message mt" v-if="errors[0]">{{ errors[0] }}</div>
        </validation-provider>
        <validation-provider
          class="user-height__item"
          name="inches"
          v-slot="{ errors }"
          :rules="{ float: true, min_value: 0, max_value: 11.9 }"
        >
          <v-input
            type="text"
            v-model="inches"
            placeholder="in"
            :class="{ error: errors[0] }"
            @blur="onImperialBlur"
          />
          <div class="error-message mt" v-if="errors[0]">{{ errors[0] }}</div>
        </validation-provider>
      </template>
      <validation-provider
        v-else
        class="user-height__item"
        name="height"
        :rules="{ float: true, required: true }"
        v-slot="{ errors, touched, pristine }"
      >
        <v-input
          type="text"
          v-model="heightValue"
          placeholder="cm"
          :class="{ error: (touched || pristine) && errors[0] }"
          @blur="onFieldBlur"
        />
        <div class="error-message mt" v-if="(touched || pristine) && errors[0]">
          {{ errors[0] }}
        </div>
      </validation-provider>

      <button
        class="button button_text user-height__toggler"
        @click="toogleUnit"
      >
        {{ togglerText }}
      </button>
    </div>

    <validation-provider
      name="common height"
      rules="user_height"
      v-slot="{ errors }"
    >
      <input
        type="text"
        class="user-height__common-height"
        :value="commonHeight"
        ref="commonHeight"
      />
      <div class="error-message mt" v-if="errors[0]">{{ errors[0] }}</div>
    </validation-provider>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  STORE_SET_CHARACTERISTICS,
  INCHE_VALUE,
  FEET_VALUE,
} from "@/constants";
import vInput from "@/components/ui/input";

export default {
  components: { vInput },

  data: () => ({
    units: "imperial",
    feet: null,
    inches: null,
  }),

  computed: {
    ...mapState({
      height: ({ characteristics }) => characteristics.height,
    }),

    commonHeight() {
      return {
        units: this.units,
        height: this.height,
      };
    },

    isImperial() {
      return this.units === "imperial";
    },

    togglerText() {
      if (this.isImperial) return "Switch to metric";
      return "Switch to ft/in";
    },

    heightValue: {
      get() {
        return this.height;
      },
      set(value) {
        const height = String(value)
          .replace(/[^\d.]/, "")
          .replace(/\.\.+/, ".");
        this[STORE_SET_CHARACTERISTICS]({ height });
      },
    },
  },

  watch: {
    heightValue: {
      immediate: true,
      handler() {
        this.setImperial();
      },
    },
  },

  methods: {
    ...mapActions([STORE_SET_CHARACTERISTICS]),

    toogleUnit() {
      if (this.isImperial) {
        this.units = "metric";
      } else {
        this.units = "imperial";
      }
    },

    onFieldBlur() {
      this.$refs.commonHeight.focus();
      this.$refs.commonHeight.blur();
    },

    setImperial() {
      const { height } = this;

      if (!+height) return;

      const feet = Math.floor(+height / FEET_VALUE);
      const inches = parseFloat(
        ((+height - feet * FEET_VALUE) / INCHE_VALUE).toFixed(1),
        10
      );
      this.feet = feet;
      this.inches = inches;
    },

    onImperialBlur() {
      const feet = +this.feet || 0;
      const inches = +this.inches || 0;
      const height = parseFloat(
        (feet * FEET_VALUE + inches * INCHE_VALUE).toFixed(1),
        10
      );
      this.heightValue = height;
      this.onFieldBlur();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/basic/variables";
@import "@/assets/styles/basic/mixins";

.user-height {
  position: relative;
  display: flex;
  justify-content: space-between;

  &__item {
    flex: 1;
    margin-right: 0.75rem;
  }

  &__common-height {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  &__toggler {
    width: 170px;
    padding: 0;
    color: $color-blue;
    white-space: nowrap;

    @include media-mobile {
      width: 130px;
      font-size: $size-xs;
    }
  }
}
</style>
