var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v("What is your height?")]),_c('div',{staticClass:"user-height"},[(_vm.isImperial)?[_c('validation-provider',{staticClass:"user-height__item",attrs:{"name":"feet","rules":{ numeric: true, required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-input',{class:{ error: errors[0] },attrs:{"type":"text","placeholder":"ft"},on:{"blur":_vm.onImperialBlur},model:{value:(_vm.feet),callback:function ($$v) {_vm.feet=$$v},expression:"feet"}}),(errors[0])?_c('div',{staticClass:"error-message mt"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,1970881160)}),_c('validation-provider',{staticClass:"user-height__item",attrs:{"name":"inches","rules":{ float: true, min_value: 0, max_value: 11.9 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-input',{class:{ error: errors[0] },attrs:{"type":"text","placeholder":"in"},on:{"blur":_vm.onImperialBlur},model:{value:(_vm.inches),callback:function ($$v) {_vm.inches=$$v},expression:"inches"}}),(errors[0])?_c('div',{staticClass:"error-message mt"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,663159701)})]:_c('validation-provider',{staticClass:"user-height__item",attrs:{"name":"height","rules":{ float: true, required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var touched = ref.touched;
var pristine = ref.pristine;
return [_c('v-input',{class:{ error: (touched || pristine) && errors[0] },attrs:{"type":"text","placeholder":"cm"},on:{"blur":_vm.onFieldBlur},model:{value:(_vm.heightValue),callback:function ($$v) {_vm.heightValue=$$v},expression:"heightValue"}}),((touched || pristine) && errors[0])?_c('div',{staticClass:"error-message mt"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])}),_c('button',{staticClass:"button button_text user-height__toggler",on:{"click":_vm.toogleUnit}},[_vm._v(" "+_vm._s(_vm.togglerText)+" ")])],2),_c('validation-provider',{attrs:{"name":"common height","rules":"user_height"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{ref:"commonHeight",staticClass:"user-height__common-height",attrs:{"type":"text"},domProps:{"value":_vm.commonHeight}}),(errors[0])?_c('div',{staticClass:"error-message mt"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }